import { Actor } from "skytree";
import { ElementStyle } from "@anderjason/web";
import { Howl } from "howler";
import ambienceMp3 from "../static/ambience.mp3";
import { SegmentWithClippingBox } from "./SegmentWithClippingBox";

// const sound = new Howl({
//   src: [ambienceMp3],
//   loop: true
// });

// sound.play();

export class Main extends Actor<void> {
  onActivate() {
    const root = this.addActor(
      RootStyle.toManagedElement({
        tagName: "div",
        parentElement: document.body,
      })
    );

    this.addActor(
      new SegmentWithClippingBox({
        parentElement: root.element
      })
    )
  }
}

const RootStyle = ElementStyle.givenDefinition({
  elementDescription: "Root",
  css: `
    bottom: 0;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
  `
});
